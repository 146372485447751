import React from "react";
import "../assets/styles/congrats.css";
import "../assets/styles/footer.css";
import Lottie from "react-lottie";
import * as animationData from "../../static/11379-green-success.json";
import Seo from "../components/SEO.jsx";
import { ImFacebook, ImYoutube2 } from "react-icons/im";
import { GrInstagram, GrLinkedinOption } from "react-icons/gr";
import { IconContext } from "react-icons";

export default function Congrats() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="container_congrats">
       <Seo />
      <h1>Pronto!</h1>
      <p>Agora verifique o e-mail que enviamos para você.</p>
      <small>Não encontrou? Verifique sua caixa de spam.</small>
      <Lottie
        loop={false}
        options={defaultOptions}
        height={200}
        width={200}
        isClickToPauseDisabled={true}
      />
      <img src={`../../logo_branca.png`} alt="Logo" />

      
      <div className="content_social_icons_congrats">
        <button
          onClick={() =>
            window.open(
              "https://www.facebook.com/SuperGeeksBrasil/",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#FFF", className: "icon_footer_congrats" }}
          >
            <ImFacebook size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://www.youtube.com/supergeeksbr",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#FFF", className: "icon_footer_congrats" }}
          >
            <ImYoutube2 size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://br.linkedin.com/company/supergeeks-brasil",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#FFF", className: "icon_footer_congrats" }}
          >
            <GrLinkedinOption size={"25"} />
          </IconContext.Provider>
        </button>
        <button
          onClick={() =>
            window.open(
              "https://www.instagram.com/SuperGeeksBrasil",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <IconContext.Provider
            value={{ color: "#FFF", className: "icon_footer_congrats" }}
          >
            <GrInstagram size={"25"} />
          </IconContext.Provider>
        </button>
      </div>
      <h3 className="footer_copyright_congrats_text">SuperGeeks © Copyright {new Date().getFullYear()}. Todos os direitos reservados.</h3>
    </div>
  );
}
